<template>
  <b-modal
    :id="id"
    :title="editData.id? $t(`${editTitle}`) : $t(`${addTitle}`)"
    size="sm"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t(okTitle)"
    :cancel-title="$t(cancelTitle)"
    centered
    no-close-on-backdrop
    @show="openModal"
    @ok.prevent="submit"
    @cancel="$bvModal.hide(`${id}`)"
  >
    <validation-observer ref="validation">
      <b-form-group
        :label="$t('general.title')"
        label-for="title_input_ru"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input
            id="title_input_ru"
            v-model="data.title"
            :placeholder="$t('general.title')"
            :state="errors.length > 0 ? false : null"
            class="mb-1 "
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('courses.price')"
        label-for="price"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('courses.price')"
        >
          <b-form-input
            id="price"
            v-model="data.price"
            :placeholder="$t('courses.price')"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('courses.subjects')"
        label-for="subject"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('courses.subjects')"
        >
          <v-select
            id="subject"
            v-model="data.subject"
            :get-option-label="el => {
              return el.title[$i18n.locale]
            }"
            :options="GET_SUBJECTS_LIST.results"
            :reduce="el => el.id"
            :placeholder="$t('courses.subjects')"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="data.is_type">
          {{ $t('others.filter_by_form_type') }}
        </b-form-checkbox>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import toast from '@/mixins/toast'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  mixins: [toast],
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      default: 'EditCourseModal',
      required: false,
    },
    addTitle: {
      type: String,
      default: 'general.add',
      required: false,
    },
    editTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    okTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    cancelTitle: {
      type: String,
      default: 'general.cancel',
      required: false,
    },
    editData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    isCourse: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLang: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      data: {
        title: {
          en: '',
          ru: '',
          uz: '',
        },
        price: 0,
      },
      required,
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
  },
  mounted() {
    if (this.isCourse) {
      this.FETCH_SUBJECTS_LIST()
    }
  },
  methods: {
    ...mapActions('users', ['FETCH_USERS_LIST']),
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST']),
    openModal() {
      if (this.isEdit) {
        this.data = this.$_removeObjectConnection(this.editData)
      } else {
        this.data = {
          title: '',
          teachers: [],
          price: 0,
          description: '',
        }
      }
    },
    submit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          this.$emit('add-button', this.data)
        } else {
          this.$_errorToast(this.$t('general.no_valid'))
        }
      })
    },
  },
}
</script>

<style>

</style>
