var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"title":_vm.editData.id? _vm.$t(("" + _vm.editTitle)) : _vm.$t(("" + _vm.addTitle)),"size":"sm","ok-variant":"success","cancel-variant":"danger","ok-title":_vm.$t(_vm.okTitle),"cancel-title":_vm.$t(_vm.cancelTitle),"centered":"","no-close-on-backdrop":""},on:{"show":_vm.openModal,"ok":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)},"cancel":function($event){return _vm.$bvModal.hide(("" + _vm.id))}}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.title'),"label-for":"title_input_ru"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('general.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1 ",attrs:{"id":"title_input_ru","placeholder":_vm.$t('general.title'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('courses.price'),"label-for":"price"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('courses.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","placeholder":_vm.$t('courses.price'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.price),callback:function ($$v) {_vm.$set(_vm.data, "price", $$v)},expression:"data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('courses.subjects'),"label-for":"subject"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('courses.subjects')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"subject","get-option-label":function (el) {
            return el.title[_vm.$i18n.locale]
          },"options":_vm.GET_SUBJECTS_LIST.results,"reduce":function (el) { return el.id; },"placeholder":_vm.$t('courses.subjects'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.subject),callback:function ($$v) {_vm.$set(_vm.data, "subject", $$v)},expression:"data.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.data.is_type),callback:function ($$v) {_vm.$set(_vm.data, "is_type", $$v)},expression:"data.is_type"}},[_vm._v(" "+_vm._s(_vm.$t('others.filter_by_form_type'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }