<template>
  <div>
    <filters
      :search="true"
      :add="
        $can('manage', 'super_admin') ||
          $can('manage', 'head_manager') ||
          $can('manage', 'content_manager')
      "
      :export-excel="
        $can('manage', 'super_admin') ||
          $can('manage', 'head_manager') ||
          $can('manage', 'content_manager')
      "
      @onExportExcel="onExportExcel"
      @filtered-items="filter"
      @add-button-clicked="submit"
    />
    <b-card>
      <b-table
        hover
        responsive
        show-empty
        :fields="fields"
        :items="GET_COURSES_LIST.results"
        style-class="slots_text_align vgt-table bordered"
        @row-clicked="
          (data) =>
            $router.push(`/course/course-administrations/theme-list/${data.id}`)
        "
      >
        <template #empty>
          <div class="text-center text-secondary">
            {{ $t("general.no_data_to_view") }}
          </div>
        </template>
        <template #head()="{ label }">
          {{ $t(label) }}
        </template>
        <template #cell(subject)="{ value }">
          {{ value.title[$i18n.locale] }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              v-if="
                $can('manage', 'super_admin') ||
                  $can('manage', 'head_manager') ||
                  $can('manage', 'content_manager')
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :title="$t('general.copying')"
              variant="primary"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="copyCourse(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="CopyIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="
                $router.push(
                  `/course/course-administrations/theme-list/${data.item.id}`
                )
              "
            >
              <feather-icon
                size="22"
                icon="ChevronsRightIcon"
              />
            </b-button>
            <b-button
              v-if="
                $can('manage', 'super_admin') ||
                  $can('manage', 'head_manager') ||
                  $can('manage', 'content_manager')
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="editCourse(data.item)"
            >
              <feather-icon
                size="22"
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-if="
                $can('manage', 'super_admin') ||
                  $can('manage', 'head_manager') ||
                  $can('manage', 'content_manager')
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="deleteCourse(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t("general.show_from") }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="pageOptions"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t("general.show_to") }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="GET_COURSES_LIST.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>
    <defoult-add-modal
      id="CourseAddModal"
      :edit-title="$t('courses.edit_course')"
      :add-title="$t('courses.add_course')"
      :is-edit="isEdit"
      :no-lang="true"
      :edit-data="editedCourse"
      :is-course="true"
      @add-button="saveCourse"
    />
    <EditCourseModal
      id="EditCourseModal"
      :edit-data="editedCourse"
      is-edit
      @add-button="saveCourse"
    />
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import filters from '@/components/filters.vue'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import DefoultAddModal from '@/components/DefoultAddModal.vue'
import EditCourseModal from '@/components/EditCourseModal.vue'

export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    BPagination,
    filters,
    BButton,
    DefoultAddModal,
    EditCourseModal,
  },
  directives: {
    Ripple,
  },

  mixins: [areYouSure, toast],
  data() {
    return {
      isEdit: false,
      pageOptions: [3, 5, 10],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      editedCourse: {},
      fields: [
        {
          label: 'users.course_title',
          key: 'title',
        },
        {
          label: 'courses.price',
          key: 'price',
        },
        {
          label: 'courses.subjects',
          key: 'subject',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_COURSES_LIST']),
  },
  mounted() {
    this.FETCH_COURSES_LIST(this.params)
  },
  methods: {
    ...mapActions('courses', ['CREATE_COURSE', 'FETCH_COURSES_LIST', 'DELETE_COURSE', 'UPDATE_COURSE', 'COPY_COURSE', 'FETCH_ONE_COURSE']),
    // opening create modal course
    submit() {
      this.isEdit = false
      this.editedCourse = {
        title: {
          ru: '',
          en: '',
          uz: '',
        },
      }
      this.$nextTick(() => {
        this.$bvModal.show('CourseAddModal')
      })
    },
    // opening edit modal course
    edit(data) {
      this.isEdit = true
      this.editedCourse = data
      this.editedCourse.subject = data.subject.id
      this.editedCourse.teachers = data.teachers.map(el => el.id)
      this.$nextTick(() => {
        this.$bvModal.show('CourseAddModal')
      })
    },
    editCourse(course) {
      this.isEdit = true
      this.editedCourse = course
      this.editedCourse.subject = course.subject.id
      this.editedCourse.teachers = course.teachers.map(el => el.id)
      this.FETCH_ONE_COURSE(course.id).then(res => {
        this.editedCourse.is_type = res.is_type
        this.$nextTick(() => {
          this.$bvModal.show('EditCourseModal')
        })
      })
    },
    copyCourse(id) {
      this.$bvModal.msgBoxConfirm(this.$t('courses.copy_confirm'), {
        title: this.$t('general.pleace_confirm'),
        size: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('general.ok'),
        cancelTitle: this.$t('general.no'),
        hideHeaderClose: false,
        centered: true,
        noCloseOnBackdrop: true,
      }).then(success => {
        if (success) {
          this.COPY_COURSE(id)
            .then(() => {
              this.$_successToast(this.$t('general.successfully_copied'))
              this.FETCH_COURSES_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    saveCourse(data) {
      if (!this.isEdit) {
        this.CREATE_COURSE(data)
          .then(() => {
            this.$_successToast(this.$t('general.added'))
            this.$nextTick(() => {
              this.$bvModal.hide('CourseAddModal')
              this.FETCH_COURSES_LIST(this.params)
            })
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      } else {
        this.UPDATE_COURSE(data)
          .then(() => {
            this.$_successToast(this.$t('general.edited'))
            this.$nextTick(() => {
              this.$bvModal.hide('CourseAddModal')
              this.$bvModal.hide('EditCourseModal')
              this.FETCH_COURSES_LIST(this.params)
            })
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      }
    },

    // delete course
    deleteCourse(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_COURSE(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.FETCH_COURSES_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    onExportExcel() {
      this.$bvModal.show('student-statistics-export')
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_COURSES_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_COURSES_LIST(this.params)
    },
    filter(filteredData) {
      this.params = { ...this.params, ...filteredData }
      this.params.page = 1
      this.FETCH_COURSES_LIST(this.params)
    },
  },
}
</script>

<style></style>
